<template>
    <div>
        <header class="flex items-center">
            <router-link to="/" id="header-logo">
                <img alt="Logo" class="h-10" src="./assets/wordpress.png">
            </router-link>
            <nav>
                <router-link to="/">Home</router-link> |
                <router-link to="/about">My CV</router-link> |
                <router-link to="/contact">Contact</router-link>
            </nav>
        </header>
        <main style="background-image: url('./asfalt-dark.png');"
            class="min-h-screen bg-gradient-to-b from-cyan-400 to-blue-500">
            <router-view v-slot="{ Component, route }">
                <transition :name="route.meta.transition || 'fade'">
                    <component :is="Component" />
                </transition>
            </router-view>
        </main>
        <footer class="text-center">
            © 2023 - {{ new Date().getFullYear() }}, Žan Zakrajšek
        </footer>
    </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;600;800&display=swap');

.fade-enter-active {
    transition: all 0.3s ease-in;
    transition-delay: .3s;
}

.fade-leave-active {
    transition: all 0.3s ease-out;
}

.fade-enter-from {
    opacity: 0;
    // transform: translateY(-50%);
}

.fade-leave-to {
    opacity: 0;
    // transform: translateY(50%);
}

:root {
    --primary-color: #08415C;
    --secondary-color: #57B8FF;
    --third-color: #D36135;
}

body {
    margin: 0;
}

#app {
    font-family: Outfit, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
    color: var(--primary-color);
}

nav {
    display: flex;
    gap: .75em;

    a {
        color: #fff;

        &.router-link-exact-active {
            color: var(--secondary-color);
        }
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--primary-color);
    color: #fff;
    padding: 30px;

    nav {
        margin-left: auto;
    }
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: .5em 0;
    background-color: var(--primary-color);
    color: #fff;
}
</style>
