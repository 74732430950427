import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faLinkedin, faTwitter, faGithub, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

const icons = [
    faLinkedin,
    faTwitter,
    faGithub,
    faFacebook,
    faInstagram,
    faEnvelope
]

icons.forEach(item => {
    library.add(item)
})


createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .mount('#app')
