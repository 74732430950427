import { createRouter, createWebHistory } from 'vue-router'

const DEFAULT_TITLE = 'Žan\'s Portfolio';

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Home',
        },
        component: () => import( '../views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: 'About',
        },
        component: () => import( '../views/AboutView.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            title: 'Contact',
        },
        component: () => import( '../views/ContactView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.afterEach((to) => {
    document.title = DEFAULT_TITLE + (to.meta.title ? (' - '+to.meta.title) : '');
});

export default router
